import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import wns from "../../../1.resources/3.images/logo/wns_white.png";
import w3mailImg from "../../../1.resources/3.images/logo/w3mail_white.png";
import w3walletImg from "../../../1.resources/3.images/logo/w3wallet_white.png";
import w3academyImg from "../../../1.resources/3.images/logo/w3academy_white.png";
import w3chatImg from "../../../1.resources/3.images/logo/w3chat_white.png";
import w3explorer from "../../../1.resources/3.images/logo/w3explorer.png";
import { ScrollBox } from "../../0.global/scrollbox/scrollbox";

function W3() {
    let w3items = [
        { name: "W3 Name Service", icon: wns, link: "https://domains.w3.one/", description: "Your Web3 username - mapping of your wallet address to human-readable names", live: true },
        { name: "W3 Mail", icon: w3mailImg, link: "https://mail.w3.one/", description: "The most secure and private email protocol built on the security of the Blockchain technology", live: true },
        { name: "W3 Explorer", icon: w3explorer, link: "https://explorer.w3.one/", description: "Explore. Monitor. Stay secure.", live: true },
        { name: "W3 Chat", icon: w3chatImg, link: "https://chat.w3.one/", description: "Simple, reliable, private messaging using your Ethereum address", live: true },
        { name: "W3 Academy", icon: w3academyImg, link: "https://academy.w3.one/", description: "Learn all about Web3 in once place", live: true },
        { name: "W3 Wallet", icon: w3walletImg, link: "https://wallet.w3.one/", description: "Your self-custody wallet for everything Web3", live: false },
    ];

    return (
        <div className="pb-10 text-left mt-10 md:mt-0 py-20 flex justify-center items-center px-5 md:px-20 lg:px-0 bg-neutral-100 ">
            <div className="md:w-[1024px]">
                <div className="text-start ml-4">
                    <p className="text-4xl md:text-5xl font-bold text-black text-center">Explore W3</p>
                    <p className="text-xl text-neutral-500 pt-4 text-center">Discover more products by W3</p>
                </div>

                <div className="grid grid-cols lg:grid-cols-2 mt-8">
                    {w3items.map((item, index) => (
                        <div className={index % 2 ? "lg:mt-0" : ""} key={index}>
                            <ScrollBox
                                elements={
                                    <div className="p-2 pt-16 pb-20 px-10 md:px-10 m-4 bg-white rounded-3xl">
                                        <div className="p-0 w-fit bg-white" style={{ borderRadius: "18px" }}>
                                            <img src={item.icon} style={{ width: "50px" }} className="rounded-2xl bg-black" />
                                        </div>
                                        <p className="font-bold mt-8 text-2xl">{item.name}</p>
                                        <p className="mt-2 text-md text-neutral-500">{item.description}</p>
                                        <div style={{ display: item.live ? "flex" : "none" }} className="mt-5 flex  items-center">
                                            <a href={item.link} target="_blank" className="text-main font-bold">
                                                Visit {item.name}
                                            </a>
                                            <FontAwesomeIcon icon={["fas", "fa-angle-right"]} style={{ fontSize: "75%", marginLeft: "10px" }} className="text-main" />
                                        </div>
                                        <div className="rounded-full px-4 mt-8 py-2" style={{ background: item.live ? "#dfecfb" : "#FDEEDF", width: "fit-content", color: item.live ? "#3482F6" : "#eb9c5c" }}>
                                            <p className="font-semibold text-sm">{item.live ? "Live" : "Development"}</p>
                                        </div>
                                    </div>
                                }
                                id={"w3item" + index}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div >
    );
}

export default W3;
