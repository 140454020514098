import React from "react";
import ReactDom from "react-dom";
import App from "./app"
import { ThemeProvider } from "./3.views/theme";
import SnackbarProvider from 'react-simple-snackbar'
import "./index.css";

ReactDom.render(
    <React.StrictMode>
        <ThemeProvider>
            <SnackbarProvider>
                <App />
            </SnackbarProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
