import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ThemeContext } from "../../theme";
import Logo from "../../../1.resources/3.images/logo/logo.png"


const Header = ({ }) => {
    const [{ theme, isDark }, toggleTheme] = useContext(ThemeContext);

    useEffect(() => {
    }, [])
    return (
        <div style={{}} className="">
            {/* <div style={{ background: theme.background, zIndex: "1000000000000000", display: window.innerWidth < 1260 ? "flex" : "none", padding: "15px", paddingLeft: "5%", paddingRight: "5%", justifyContent: "space-between" }} className="onlyvertcenter">

                <a href="/" style={{ display: "flex", alignItems: "center", cursor: "pointer" }} >
                    <img src={Logo} style={{ width: "25px" }} />
                    <p style={{ color: "#fff", marginLeft: '5%', fontSize: "120%", fontWeight: "bold", whiteSpace: "nowrap" }}>W3</p>
                </a>
                <div className="onlyvertcenter">
                </div>
            </div> */}
            <div style={{ display: "block" }} >
                <div style={{ padding: window.innerWidth < 800 ? "5%" : "1.5%", paddingLeft: "10%", paddingRight: "10%", justifyContent: "space-between" }} className="onlyvertcenter">
                    <a href="/" style={{ display: "flex", alignItems: "left", cursor: "pointer" }} className="onlyvertcenter">
                        {/* <img src={Logo} style={{ width: "50px" }} /> */}
                        <p style={{ color: "#fff", marginLeft: '0%', fontSize: "180%", fontWeight: "normal", whiteSpace: "nowrap", letterSpacing: "2px" }}>W<span style={{ fontWeight: "bold" }}>3</span></p>
                    </a>

                </div>
            </div >
        </div >
    );

}


export default Header;
