import React, { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//Imgs
import Logo from "../../../1.resources/3.images/logo/logo3.png";
import { ThemeContext } from "../../theme";

function Footer() {
    const [{ theme, isDark }, toggleTheme] = useContext(ThemeContext);

    return (
        <div id="headerbar" style={{ background: "#000", paddingTop: window.innerWidth < 767 ? "10%" : "0%", paddingBottom: window.innerWidth < 767 ? "10%" : "2.5%" }}>
            {/*  <div style={{ paddingRight: window.innerWidth < 767 ? "10%" : "25%", paddingLeft: window.innerWidth < 767 ? "10%" : "25%" }}>
                <div id="lowerfooter" style={{ display: window.innerWidth < 767 ? "block" : 'flex' }}>
                    <div id="footerleft" style={{ width: window.innerWidth < 767 ? "100%" : '60%' }}>
                        <div id="main">
                            <div style={{ display: 'flex' }}>
                                <div id="pages" style={{ width: '50%' }}>
                                    <p className="navcats" style={{ color: "white", fontWeight: "bold" }}>Pages</p>
                                    <a className="graylink" href="/">
                                        <p className="navlinks">Home</p>
                                    </a>
                                    <a className="graylink" href="/marketplace">
                                        <p className="navlinks">Marketplace</p>
                                    </a>
                                    <a className="graylink" href="https://chrome.google.com/webstore/detail/web3-name-service-wns/jpcmgjfdfgakjebkgpakliolapnaglkd" target="_blank">
                                        <p className="navlinks">Extension</p>
                                    </a>
                                    <a className="graylink" href="https://blog.wnsdomains.io/">
                                        <p className="navlinks">Blog</p>
                                    </a>
                                    <a className="graylink" href="https://docs.wnsdomains.io/">
                                        <p className="navlinks">Docs</p>
                                    </a>
                                </div>
                                <div id="links" style={{ width: '50%' }}>
                                    <p className="navcats" style={{ color: "white", fontWeight: "bold" }}>Resources</p>
                                    <a className="graylink" href="https://docs.wnsdomains.io/about/introduction">
                                        <p className="navlinks">Introduction</p>
                                    </a>
                                    <a className="graylink" href="https://docs.wnsdomains.io/guides/registering-domains">
                                        <p className="navlinks">Registration</p>
                                    </a>
                                    <a className="graylink" href="https://docs.wnsdomains.io/manage/setting-primary-name">
                                        <p className="navlinks">Management</p>
                                    </a>
                                    <a className="graylink" href="https://docs.wnsdomains.io/resolve/apis">
                                        <p className="navlinks">Resolution</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <br />

                    </div>
                    <div id="footerright" style={{ width: window.innerWidth < 767 ? "100%" : '40%' }}>
                        <div id="social">
                            <div id="join" style={{}}>
                                <p className="navcats" style={{ fontWeight: 'bold', color: "white" }}>Join the WNS community</p>
                                <div id="joinbtn" style={{ display: 'flex', marginTop: '5%' }}>
                                    <a className="graylink" href="https://twitter.com/wnsdomains" target="_blank" style={{ cursor: "pointer", padding: "5%", fontSize: "110%", borderRadius: "50%", background: theme.main2, width: "50px", height: "50px" }} className="vertcenter">
                                        <FontAwesomeIcon icon={['fab', 'fa-twitter']} style={{ background: theme.main2, color: "#fff" }} />
                                    </a>
                                </div>
                            </div>
                            <br />
                            <div id="download" style={{}}>
                                <p className="navcats" style={{ fontWeight: 'bold', color: "white", marginBottom: "2%" }}>Download the Chrome Extension</p>
                                <div id="downloadbtn" style={{ display: 'flex', marginTop: '5%' }}>
                                    <a className="graylink" href="https://chrome.google.com/webstore/detail/web3-name-service-wns/jpcmgjfdfgakjebkgpakliolapnaglkd" target="_blank" style={{ cursor: "pointer" }}>
                                        <button className="" style={{
                                            display: "flex", padding: "17.5px", paddingRight: "15%",
                                            background: theme.main2, color: "white", borderRadius: "50px", minWidth: "max-content"
                                        }}>
                                            <div style={{ width: "auto" }}>
                                                <FontAwesomeIcon icon={['fab', 'fa-chrome']} style={{ fontSize: "175%" }} />
                                            </div>
                                            <div style={{ width: "auto", marginLeft: "5%" }}>
                                                <p style={{ fontSize: "60%", fontWeight: "normal", marginBottom: "2.5%" }}>Download on the</p>
                                                <p style={{ fontSize: "80%" }}>Chrome&nbsp;Web&nbsp;Store</p>
                                            </div>

                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div id="desktopheader" style={{ paddingTop: window.innerWidth < 767 ? "15%" : "2.5%" }}>
                <div style={{ padding: "0.25%", paddingLeft: window.innerWidth < 767 ? "10%" : "25%", paddingRight: window.innerWidth < 767 ? "10%" : "25%", justifyContent: "space-between" }} className={window.innerWidth < 767 ? "" : "onlyvertcenter"}>
                    <div>
                        <a className="graylink" href="/" style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                            {/*  <img src={Logo} style={{ width: "35px" }} /> */}
                            <p style={{ color: "#fff", marginLeft: '20%', fontSize: "150%", fontWeight: "normal", whiteSpace: "nowrap", letterSpacing: "2px" }}>W<span style={{ fontWeight: "bold" }}>3</span></p>
                        </a>
                        {/* <div style={{ marginTop: "6%" }}>
                            <p style={{ color: "#fff", fontSize: "80%", fontWeight: "bold", whiteSpace: "nowrap" }}>Contact: <span style={{ fontWeight: "normal" }}>mail@w3.one</span></p>
                        </div> */}
                    </div>
                    <div style={{ paddingTop: window.innerWidth < 767 ? "5%" : "0%" }}>
                        <p style={{ fontSize: '80%', color: "#808080", fontWeight: "bold" }} >contact@w3.one</p>
                        <p style={{ fontSize: '60%', color: '#808080', textAlign: window.innerWidth < 767 ? "left" : "right", fontWeight: "bold" }}>© W3 Labs, 2021</p>
                    </div>
                </div>
            </div>
        </div >

    );
}

export default Footer;


