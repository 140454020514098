import React, { useState, useContext } from "react";

import { ThemeContext } from "../theme";
import W3 from "./partials/w3";

const Home = ({ walletConnected, setWalletConnected }) => {
    const [{ theme, isDark }, toggleTheme] = useContext(ThemeContext);

    return (
        <div>
            <div id="desktop" className="">

                {/* <div style={{ marginTop: window.innerWidth < 800 ? "15%" : "0%", marginBottom: "2.5%", textAlign: window.innerWidth < 800 ? "left" : "center", marginLeft: window.innerWidth < 800 ? "10%" : "0%", marginRight: window.innerWidth < 800 ? "10%" : "0%" }}>
                    <p style={{ fontSize: "200%", fontWeight: "bold", color: theme.text }}>Products</p>
                    <p style={{ marginTop: window.innerWidth < 800 ? "2.5%" : "0%", color: theme.text, fontSize: "100%", fontWeight: "normal" }}>Implementations of Web3</p>

                </div> */}
                <W3 />

            </div>
        </div>
    );

}

export default Home;
