import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//Imgs
import { ThemeContext } from "../../theme";

let items = [{ "name": "olive.web3", "cost": "0" },
{ "name": "hope.web3", "cost": "0" },
{ "name": "john.web3", "cost": "0" },
{ "name": "michael.web3", "cost": "0" },
{ "name": "lisa.web3", "cost": "0" },
{ "name": "richard.web3", "cost": "0" }]
const Hero = ({ walletConnected, setWalletConnected }) => {
    const [{ theme, isDark }, toggleTheme] = useContext(ThemeContext);

    return (
        <div style={{ height: window.innerHeight * 0.7, paddingLeft: "5%", paddingRight: "5%" }} className="vertcenter" >
            <div>
                <div style={{ marginTop: "-30%", textAlign: "center" }}>
                    <p className="text-white text-8xl font-bold">Welcome to W3!</p>
                    <p className="text-white mt-6 text-2xl">Paving the road for the third generation of Web</p>
                    <div className="mt-8">
                        <a href="#content"><button className="whitebutton">Explore</button></a>
                    </div>
                </div>
                <div className="vertcenter" style={{ width: "100%", position: "absolute", bottom: "10%", left: "0%" }}>
                    <FontAwesomeIcon icon={['fas', 'fa-angle-down']} style={{ fontSize: "200%", color: "#fff" }} />
                </div>
            </div>
        </div >

    );
}

export default Hero;

