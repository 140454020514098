import React, { useState, useEffect, useContext } from "react";
import Header from "./3.views/0.global/header/header";

import Home from "./3.views/1.home/home";
import Footer from "./3.views/1.home/partials/footer";
import Hero from "./3.views/1.home/partials/hero";
import { ThemeContext } from "./3.views/theme";
import LandingImg2 from "./1.resources/3.images/images/bg.gif";
const Main = ({ }) => {
    const [{ theme, isDark }, toggleTheme] = useContext(ThemeContext);
    const [path, setPath] = useState();

    async function init() {
    }
    useEffect(() => {
        init();
        console.log(window.location.pathname)
        let path = window.location.pathname;
        let array = path.split("/");
        console.log(array);
        if (array[1] == "") {
            setPath(0);
        }
    }, []);

    return (
        <div style={{ backgroundColor: theme.background }}>
            {path != null ? (

                <div id="desktop">
                    <div style={{ position: "static", top: "0", height: window.innerHeight, background: `url(${LandingImg2})`, boxShadow: "inset 0 0 0 2000px rgba(0,0,0, 0.3)", backgroundPosition: "center center", backgroundSize: "contain", position: "relative" }} className="">
                        <Header />
                        <div style={{ width: "100%", height: window.innerHeight * 0.85, display: "flex", alignItems: "center" }} className="vertcenter">
                            <Hero />
                        </div>
                    </div>
                    <div id="content">
                        <Home />
                    </div>
                    {/* <div style={{ backgroundColor: "#000" }}>
                        <Wns />
                        <Footer />
                    </div> */}
                </div>
            ) : (
                <div />
            )
            }
        </div >
    );

}

export default Main;


/* position: "absolute", top: "50%", transform: "translateY(-50%)" */