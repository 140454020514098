import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./1.resources/1.css/1.mainpages/index/index.css"

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

import "./1.resources/2.js/0.global/0.smallfunctions/imports_css";
import Main from "./main";

library.add(fas, fab, far);
class App extends React.Component {
    componentDidMount() {

    }

    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/*" element={<Main />} />
                    {/* <Route path="/inbox*" element={<WNSApp />} /> */}
                    {/*
                    <Route path="*" element={"<NoPage />"} /> */}
                </Routes>
            </BrowserRouter>
        );
    }
}

export default App;