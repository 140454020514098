import { useState } from "react";
import { createContext } from "react";


const themes = {
    light: {
        theme: "light",
        background: "#ffffff",
        background2: "#f4f4f4",
        background3: "#e5e5e5",
        text: "#000",
        main: "#8A54EA",
        main2: "#a675ff",
        subtext: "#808080",
        subbackground: "#fff",
        subbackground2: "#f0f0f0",
        subbackground3: "#e5e5e5",
        border: "#d0d0d0",
        border2: "#808080",
        skeleton1: "#f0f0f0",
        skeleton2: "#f9f9f9",
        skeleton3: "#e5e5e5",
        skeleton4: "#d0d0d0",
        mainbackground: "#D3C9F8",
        white: "#fff",
        black: "#000"

    },
    dark: {
        theme: "dark",
        background: "#1e1d20",
        background2: "#333038",
        background3: "#3d3943",
        text: "#fff",
        main: "#7b3fe4",
        main2: "#a675ff",
        subtext: "#808080",
        subbackground: "#101010",
        subbackground2: "#202020",
        subbackground3: "#272727",
        border: "#202020",
        border2: "#808080",
        skeleton1: "#333038",
        skeleton2: "#2d2b32",
        skeleton3: "#3d3943",
        skeleton4: "#423d48",
        mainbackground: "#D3C9F8",
        white: "#fff",
        black: "#000"
    }
}

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [isDark, setIsDark] = useState(false);
    const theme = isDark ? themes.dark : themes.light;

    const toggleTheme = () => {
        setIsDark(!isDark);
    }

    return (
        <ThemeContext.Provider value={[{ theme, isDark }, toggleTheme]}>
            {children}
        </ThemeContext.Provider>
    )
}